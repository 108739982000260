<!--
 * @Description: 
 * @Autor: zhangzhang
 * @Date: 2021-06-10 16:00:34
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-02-11 10:55:36
-->
<template>
  <SideBar :groups="groups"></SideBar>
</template>

<script>
import SideBar from '@/components/SideBar'
export default {
  components: {
    SideBar
  },

  data() {
    return {
      groups: [
        {
          label: '项目',
          list: [
            {
              name: '全部项目',
              path: '/admin/project/projects'
            },
            {
              name: '用户注册',
              path: '/admin/project/automatic?index='+2
            },
            {
              name: '后台创建',
              path: '/admin/project/backstage?index='+3
            }
          ]
        },
		{
		  label: '模版',
		  list: [
		    {
		      name: '全部模版',
		      path: '/admin/project/models'
		    }
		  ]
		}
      ]
    }
  }
}
</script>
